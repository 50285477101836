export function H1 ({ children }) {
  return <h1 className='text-4xl font-bold mb-3 flex gap-4'>{children}</h1>
}

export function H2 ({ children, className }) {
  return <h2 className={`text-2xl font-bold mb-3 flex gap-4 ${className}`}>{children}</h2>
}

export function H3 ({ children }) {
  return <h3 className='text-xl'>{children}</h3>
}

export function PageHeading ({children, title, image = null}) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2'>
      <H1>
        {image && <img src={image} className='w-[35px]' alt='' />}
        {title}
      </H1>
      <div className='flex items-center justify-end gap-3'>{children}</div>
    </div>
  )
}
