import React from 'react'
import useLocale from './useLocale'
import items from '../config/items.json'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import ClinicCaseForm from '../models/Form'

function useCCLForm () {
  const [formItems, setFormItems] = React.useState({items: [], name: ''})
  const [id, setId] = React.useState(null)
  const {l} = useLocale()
  const {form} = useParams()
  const updating = id !== null

  const ItemKeys = Object.keys(formItems.items)

  const handleChange = e => {
    const {value, name} = e.target

    setFormItems({
      ...formItems,
      [name]: value,
    })
  }

  function handleCheckbox (e) {
    const {value, checked} = e.target

    const aux = JSON.parse(JSON.stringify(formItems))

    if (!aux.classifiers) aux.classifiers = []

    if (checked) {
      aux.classifiers.push(value)
    } else {
      const index = aux.classifiers.indexOf(value)
      aux.classifiers.splice(index, 1)
    }


    setFormItems({...aux})
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (updating === true) {
      ClinicCaseForm.update(formItems, id)
        .then(res => toast.success(l('form_saved')))
        .catch(err => toast.error(l('general_error')))
      return
    }
    ClinicCaseForm.save(formItems).then(res => toast.success(l('form_saved')))
  }

  React.useEffect(() => {
    ClinicCaseForm.show(form)
      .then(res => {
        handleUpdate(res)
      })
      .catch(err => toast.error(l('general_error')))
  }, [form])

  const handleDrop = e => {
    const id = e.dataTransfer.getData('id')
    const item = items.filter(item => item.id == id)[0]

    const aux = formItems
    aux.items.push(JSON.parse(JSON.stringify(item)))
    setFormItems({...aux})
  }

  const handleSubDrop = (index, e) => {
    if (
      formItems.items[index].component === 'section' &&
      e.dataTransfer.getData('id') === '4'
    )
      return toast.error(l('section_on_section'))

    const id = e.dataTransfer.getData('id')
    const item = items.filter(item => item.id == id)[0]

    const aux = formItems
    aux.items[index].items.push(JSON.parse(JSON.stringify(item)))

    setFormItems({...aux})
  }

  const handleInputChange = (e, index, itemIndex = null) => {
    const aux = formItems
    const {name, value} = e.target

    if (itemIndex !== null) {
      aux.items[index].items[itemIndex].attributes[name] = value
    } else {
      aux.items[index].attributes[name] = value
    }

    setFormItems({...aux})
  }

  const handleRadioChange = (e, index, itemIndex, subIndex = null) => {
    const aux = formItems
    const {name, value} = e.target

    if (subIndex !== null) {
      aux.items[index].items[itemIndex].attributes.items[subIndex][name] = value
    } else {
      aux.items[index].attributes.items[itemIndex][name] = value
    }

    setFormItems({...aux})
  }

  const handleChoiceCheckChange = (e, index, itemIndex, subIndex = null) => {
    const aux = formItems
    const {name, checked} = e.target

    if (subIndex !== null) {
      aux.items[index].items[itemIndex].attributes.items[subIndex][name] = checked
    } else {
      aux.items[index].attributes.items[itemIndex][name] = checked
    }

    setFormItems({...aux})
  }

  const handleAddRow = (index, item = {}, itemIndex = null) => {
    const aux = formItems

    if (itemIndex !== null) {
      aux.items[index].items[itemIndex].attributes.items.push(item)
    } else {
      aux.items[index].attributes.items.push(item)
    }

    setFormItems({...aux})
  }

  const handleDelete = (index, itemIndex = null, subItemIndex = null) => {
    let aux = formItems

    if (itemIndex === null && subItemIndex === null) delete aux.items.splice(index, 1)
    if (itemIndex !== null && subItemIndex === null)
      delete aux.items[index].items.splice(itemIndex, 1)
    if (itemIndex !== null && subItemIndex !== null)
      delete aux.items[index].items[itemIndex].attributes.items.splice(subItemIndex, 1)
    if (itemIndex === null && subItemIndex !== null)
      delete aux.items[index].attributes.items.splice(subItemIndex, 1)

    setFormItems({...aux})
  }

  const handleMove = (action, index, subIndex = null) => {
    const data = formItems

    if (subIndex === null) {
      if (action === 'up') {
        const itemIndex = ItemKeys.indexOf(index)
        if (itemIndex === 0) return
        const lastIndex = ItemKeys[itemIndex - 1]
        const aux = data.items[lastIndex]
        data.items[lastIndex] = data.items[index]
        data.items[index] = aux
      }

      if (action === 'down') {
        const itemIndex = ItemKeys.indexOf(index)
        if (itemIndex === ItemKeys.length - 1) return
        const lastIndex = ItemKeys[itemIndex + 1]
        const aux = data.items[lastIndex]
        data.items[lastIndex] = data.items[index]
        data.items[index] = aux
      }
      setFormItems({...data})
      return
    }

    if (action === 'up') {
      const indexes = Object.keys(data.items[index].items)
      const itemIndex = indexes.indexOf(subIndex)
      if (itemIndex === 0) return
      const lastIndex = indexes[itemIndex - 1]
      const aux = data.items[index].items[lastIndex]
      data.items[index].items[lastIndex] = data.items[index].items[subIndex]
      data.items[index].items[subIndex] = aux
    }

    if (action === 'down') {
      const indexes = Object.keys(data.items[index].items)
      const itemIndex = indexes.indexOf(subIndex)
      if (itemIndex === indexes.length - 1) return
      const lastIndex = indexes[itemIndex + 1]
      const aux = data.items[index].items[lastIndex]
      data.items[index].items[lastIndex] = data.items[index].items[subIndex]
      data.items[index].items[subIndex] = aux
    }

    setFormItems({...data})
  }

  function uncheckHasQuestions (aux, index) {
    aux.items[index].items = aux.items[index].items.map(item => ({
      ...item,
      attributes: {
        ...item.attributes,
        has_questions: false,
      }
    }))
  }

  const handleCheckboxChange = (e, index, subIndex = null) => {
    const aux = formItems
    const {name, checked} = e.target

    if (name === 'is_multiple' && checked === true) uncheckHasQuestions(aux, index)

    if (subIndex !== null) {
      aux.items[index].items[subIndex].attributes[name] = checked
    } else {
      aux.items[index].attributes[name] = checked
    }

    setFormItems({...aux})
  }

  const handleUpdate = data => {
    setId(data._id)
    delete data?._id
    delete data?.__v
    setFormItems({...data})
  }

  const handleRoleChange = e => {
    const {name} = e.currentTarget

    const index = formItems.roles.indexOf(name)

    if (index === -1) {
      setFormItems({
        ...formItems,
        roles: [...formItems.roles, name],
      })
      return
    }

    let aux = formItems
    aux.roles.splice(index, 1)
    setFormItems({...aux})
  }

  return {
    formItems,
    setFormItems,
    handleAddRow,
    handleDrop,
    handleInputChange,
    handleRadioChange,
    handleSubDrop,
    handleDelete,
    handleUpdate,
    id,
    handleSubmit,
    handleChange,
    handleRoleChange,
    handleMove,
    handleCheckboxChange,
    handleChoiceCheckChange,
    handleCheckbox
  }
}

export default useCCLForm
